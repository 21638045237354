import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';

const RendezvenyekPage = () => (
  <Layout page="rendezvenyek">
    <article id="main">
      <header>
        <h2>Rendezvények</h2>
        <p>Borkóstolók, esküvők, családi rendezvények, szállás</p>
      </header>
    </article>

    <section id="one" className="wrapper alt style2">
      <section id="borkostolo" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/tasting.jpg"
            alt="Borkóstoló"
          />
        </div>
        <div className="content">
          <h2>Borkóstoló</h2>
          <p>
            A pincészetünk Eger egyik tradicionális pincesorán, a Kőlyukon
            található, ahol a borvidékre jellemző riolit tufába vájt családi
            pincék sorakoznak már évszázadok óta. Mi is a családi pincét
            bővítettük az eltelt évtizedekben, amely mára az Egri borvidék
            legnagyobb ászokhordó kapacitásával rendelkezik. Ugyancsak itt
            találhatóak a palackos érlelő pincéink, amelyek szintén lenyűgöző
            látványt nyújtanak. A pincéket látogathatóvá alakítottuk, hogy
            vendégeink minél közelebbről ismerhessék meg az itt folyó munkát.
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/tasting_2.jpg"
            alt="Borkóstoló"
          />
        </div>
        <div className="content">
          <p>
            A pincészethez tartozik egy minden igényt kielégítő vendégfogadó
            komplexum is, amely egyszerre idézi fel a klasszikus borospince
            hangulatot, ugyanakkor modern kommunikációs eszközökkel segít
            megismertetni vendégeinkkel a borvidéket, a dűlőinket,
            végigkövethetik a szőlő útját a szürettől egészen a palackozásig. A
            meghitt borkóstoló sarkok kiváló helyszínt biztosítanak egyedi
            borkóstolásokra, az impozáns kóstolóterem pedig családi, baráti,
            céges rendezvények, exkluzív fogadások, esküvők számára is ideális
            100 főig.
          </p>
        </div>
      </section>

      <section id="borkostolo-egyeni" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/personal.jpg"
            alt="Borkóstoló pincelátogatással egyéni látogatóknak"
          />
        </div>
        <div className="content">
          <h2>Borkóstoló pincelátogatással egyéni látogatóknak</h2>
          <p>
            Nyitvatartási időben pincészetünkben előzetes bejelentkezés nélkül
            is van lehetőség borkóstolásra és vásárlásra. Előzetes időpont
            egyeztetéssel pincelátogatást és a szakmai idegenvezetés is tudunk
            biztosítani, azonban ez minden esetben időpont foglaláshoz között.
          </p>
          <p>
            <b>Nyitva tartás</b>
            <br style={{ display: 'block' }} />
            Hétfőtől-péntekig: 08-16 óra között, szombaton: 09-18 óra között.
          </p>
          <p>
            <b>Bejelentkezés, időpont egyeztetés:</b>
            <br style={{ display: 'block' }} />
            E-mail: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Telefon: +36 20 215 1608
          </p>
        </div>
      </section>

      <section id="borkostolo-csoportos" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/winery.jpeg"
            alt="Borkóstoló pincelátogatással csoportok számára"
          />
        </div>
        <div className="content">
          <h2>Borkóstoló pincelátogatással csoportok számára</h2>
          <p>
            Elsősorban előzetes bejelentkezés alapján, csoportoknak is
            szervezünk pincelátogatással egybekötött, szakértő borkóstolást, a
            hét bármely napján. Ezekhez borkorcsolyákat is tudunk kínálni, amely
            lehet friss pogácsa vagy komplett falusi vegyes ízelítő tál. Igény
            esetén meleg étel felszolgálására is van lehetőség.
          </p>
          <p>
            <b>Bejelentkezés, időpont egyeztetés: </b>
            <br style={{ display: 'block' }} />
            E-mail: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Telefon: +36 20 215 1608
          </p>
        </div>
      </section>

      <section id="kitelepult-borkostolo" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/external.jpeg"
            alt="Kitelepült borkóstoló"
          />
        </div>
        <div className="content">
          <h2>Kitelepült borkóstoló</h2>
          <p>
            Előzetes egyeztetés alapján az ország egész területén tartunk
            borainkból kóstolókat, illetve borvacsorákhoz biztosítjuk a
            fogásokhoz illő borokat és a magas szakmai színvonalú bemutatót.
          </p>
          <p>
            <b>Ajánlatkérés, időpont egyeztetés: </b>
            <br style={{ display: 'block' }} />
            E-mail: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Telefon: +36 20 215 1608
          </p>
          <p>
            Keressen bennünket, és beszéljünk meg egy ideális időpontot a
            kóstolóra!
          </p>
        </div>
      </section>

      <section id="eskuvo" className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/location.jpg"
            alt="Esküvőhelyszín"
          />
        </div>
        <div className="content">
          <h2>Esküvőhelyszín</h2>
          <p>
            Tradicionális családi pincészetünk 2012-ben egy új, minden igényt
            kielégítő kóstoló- és rendezvényteremmel bővült. Egerben egyedülálló
            módon szerettük volna ötvözni a természet adta nyugodt, kellemes
            helyszínt, egy korszerű európai színvonallal, amely megfelelő
            helyszíne lehet álomesküvő megszervezésének. Egri komplexumunk
            megfelelő környezetet biztosít klasszikus és modern esküvő
            megvalósítására is. Célunk, hogy ez a szép alkalom valóban
            felejthetetlen és tökéletes legyen az első pillanattól kezdve.
          </p>
          <p>
            Rendezvénytermünk 100 fő részére biztosít kényelmes ünneplési
            helyszínt
          </p>
          <p>
            Nagy tapasztalattal rendelkező csapatunk nem csak az esküvő napján
            bonyolítja le az esküvőt célunk, felajánljuk, hogy Önök, az Ifjú
            Pár, a Családtagok előtte már 2 nappal is igénybe vegyék a
            helyszínt.
          </p>
          <p>
            <ul>
              <li>
                Az ifjú pár és a családtagok számára külön kulccsal rendelkező
                öltöző szobát biztosítunk, amely alkalmas az ajándékok
                tárolására is
              </li>
              <li>
                A helyszín ingyenes, őrzött (!) parkolást biztosít a rendezvény
                teljes ideje alatt
              </li>
              <li>
                A Juhász Testvérek Pincészete a gyermekeket sem felejtette el a
                rendezvényház építése alkalmával, korszerű, jól felszerelt,
                vadonatúj gyermekjátszó gondoskodik a kicsik szórakozásáról,
                amelyhez igény esetén szakképzett felügyelő is tartozhat
              </li>
            </ul>
          </p>
          <p>
            <b>Ajánlatkérés, bejelentkezés, időpont egyeztetés: </b>
            <br style={{ display: 'block' }} />
            E-mail: borkostolo@juhaszvin.hu
            <br style={{ display: 'block' }} />
            Telefon: +36 20 215 1608
          </p>
        </div>
      </section>

      <section className="spotlight">
        <div className="image">
          <StaticImage
            src="../../assets/images/rendezvenyek/ceremony.jpg"
            alt="Szertartásvezető partnerünk"
          />
        </div>
        <div className="content">
          <h2>
            Szertartásvezető partnerünk
            <br style={{ display: 'block' }} />
            Kovács Döniz - Szertartásvezető
          </h2>
          <p>
            A tökéletes esküvői szertartás érzelmes és egyedi, mint a szerelem.
            Mint szertartásvezető, lehetőségem van előzetesen megismerni a párt,
            személyessé varázsolni az esküvőt. A közös történetek beszédbe
            csomagolása az én hivatásom. Szenvedéllyel tervezek meg minden
            szertartást, esketéseimre maximális átéléssel készülök. Célom,
            hogyha majd évekkel később a házaspár visszagondol az esküvőjük
            napjára, azt mondhassa, Róluk szólt. Keressetek bizalommal és
            valósítsuk meg együtt közös életetek első, legszebb napját ott ahol
            megálmodtátok, akkor amikor akarjátok, úgy ahogy elképzeltétek,
            olyan szöveggel, amit szeretnétek.
          </p>
          <p>Házassági fogadalom megerősítése</p>
          <p>
            Egy házaspár életében fontos és különleges dátumok a házassági
            évfordulók. Kedves szokás ezen évfordulón meglepni egymást egy
            meghitt vacsorával, egy szép csokor virággal, egy együtt töltött
            hétvégével. És még különlegesebb ezen alkalomból megerősíteni a
            házasságkötéskor tett fogadalmat. Egy kerek házassági évfordulón
            tett fogadalom megerősítés a házastárs megbecsülését jelképezi, mely
            a közösen eltöltött évek alatt egyre csak mélyül.
          </p>
          <p>
            Tel.: + 36 20 980 5186
            <br style={{ display: 'block' }} />
            E-mail: kovacsdonizszertartasvezeto@gmail.com
            <br style={{ display: 'block' }} />
            <a
              href="https://www.instagram.com/szertartasvezeto_eger/"
              target="__blank"
            >
              Instagram
            </a>
            <span> </span>
            <a
              href="https://www.facebook.com/kovacsdonizszertartasvezeto/"
              target="__blank"
            >
              Facebook
            </a>
          </p>
        </div>
      </section>
    </section>
  </Layout>
);

export default RendezvenyekPage;
